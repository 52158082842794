export const worldActivityContent = {
  id: "world-activity",
  // page: "last-1h",
  title: "Activity last 24h",
  url: "/activity",
  description: [
    "Enjoy this global view of our World Heat Map.",
    "This represents all the pinpoints uploaded to the ecosystem during the last 24 hours!",
  ],
  dataset: [],
}

export const worldActivityImageContent = {
  id: "world-activity-image",
  // page: "last-1h",
  title: "Activity last 24h",
  url: "/activity-image",
  description: [
    "Enjoy this global view of our World Heat Map.",
    "This represents all the pinpoints uploaded to the ecosystem during the last 24 hours!",
  ],
  dataset: [],
}

export const lastHourActivityContent = {
  id: "activity-last-1h",
  // page: "last-1h",
  title: "Last Hour Activity",
  url: "/activity/last-1h",
  description: [
    "Enjoy this global view of our World Heat Map.",
    "This represents all the pinpoints uploaded to the ecosystem during the last 60 minutes!",
  ],
  dataset: [],
}

export const userFootprintActivityContent = {
  id: "activity-user-footprint",
  // page: "user-footprint",
  title: "User Footprint",
  url: "/activity/user-footprint",
  description: [
    "Here you can see the global footprint of the users.",
    "This map presents aggregated data that summarizes the activity of the platform during the last year.",
  ],
  dataset: [],
}

export const userInitialFootprintActivityContent = {
  id: "activity-user-initial-footprint",
  // page: "user-initial-footprint",
  title: "User Initial Footprint",
  url: "/activity/user-initial-footprint",
  description: [
    "Here the first footprint registered by each user.",
    "This map presents the first record of each platform user during the last year.",
  ],
  dataset: [],
}

export const fakeActivityContent = {
  id: "activity-fake",
  // page: "user-initial-footprint",
  title: "Fake Activity",
  url: "/activity/fake-activity",
  description: [
    "This map has been generated with simulated data, with the sole purpose of illustrating a future scenario of global coverage of the platform's activity.",
  ],
  dataset: [],
}

// id: "user-footprint",
// url: "/activity/user-footprint",

// id: "fake-activity",
// url: "/activity/fake-activity",

// export const realTimeActivityContent = {
//   title: "Activity in real time",
//   description: [
//     "This map consists of a dynamic visualization of the activity of the platform during the last hour. Nulla finibus, ligula id sagittis volutpat, diam quam varius ante, ut accumsan purus enim sit amet quam.",
//     "This map consists of a dynamic visualization of the activity of the platform during the last hour. Nulla finibus, ligula id sagittis volutpat, diam quam varius ante, ut accumsan purus enim sit amet quam.",
//     "This map consists of a dynamic visualization of the activity of the platform during the last hour. Nulla finibus, ligula id sagittis volutpat, diam quam varius ante, ut accumsan purus enim sit amet quam.",
//   ],
//   dataset: [],
// }

/**
 * STORIES - SMART CITIES
 */

export const largeScaleSmartCitiesContent = {
  id: "smartcities-large-scale",
  // page: "smartcities-large-scale",
  title: "Madrid",
  url: "/stories/smartcities-large-scale",
  description: ["Total City View."],
  dataset: [],
}

export const mediumScaleSmartCitiesContent = {
  id: "smartcities-medium-scale",
  // page: "smartcities-medium-scale",
  title: "London",
  url: "/stories/smartcities-medium-scale",
  description: ['London´s financial district "The city" 3D Zoom.'],
  dataset: [],
}

export const localScaleSmartCitiesContent = {
  id: "smartcities-local-scale",
  // page: "smartcities-local-scale",
  title: "Paris",
  url: "/stories/smartcities-local-scale",
  description: ["Eiffel Tower surroundings. Tourism Afflux."],
  dataset: [],
}

export default [
  // Activity
  // lastHourActivityContent,
  // userFootprintActivityContent,
  // userInitialFootprintActivityContent,
  // fakeActivityContent,
  worldActivityContent,
  worldActivityImageContent,
  // last24HourActivityContent,
  // realTimeActivityContent,
  // Stories
  largeScaleSmartCitiesContent,
  mediumScaleSmartCitiesContent,
  localScaleSmartCitiesContent,
]
