/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import React from "react"
import { Box } from "@theme-ui/components"

// import OrbAnimation from "../animations/orb-animation"
import ShadowAnimation from "../animations/shadow-animation"

const backgroundDark =
  "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,0))"
const backgroundLight =
  "linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,0))"

const SideboxMapView = ({ map, text, loadedMap, viewType }) => {
  const [colorMode] = useColorMode()
  return (
    <>
      <div
        sx={{
          variant:
            viewType === "activity"
              ? "styles.fullMapViewActivity"
              : "styles.fullMapViewStories",
        }}
      >
        {/* {!loadedMap && <OrbAnimation />} */}
        {!loadedMap && <ShadowAnimation />}
        {map}
        <Box
          sx={{
            variant: "styles.sidebox",
            backgroundImage:
              colorMode === "dark" ? backgroundDark : backgroundLight,
          }}
        >
          {text}
        </Box>
      </div>
      <Box
        sx={{
          variant: "styles.bottombox",
          backgroundImage:
            colorMode === "dark" ? backgroundDark : backgroundLight,
        }}
      >
        {text}
      </Box>
    </>
  )
}

export default SideboxMapView
