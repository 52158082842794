import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import useWindowSize from "../hooks/useWindowSize"
import usePrevious from "../hooks/usePrevious"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

import HeatmapActivity from "../components/sections/heatmap-activity"
import content from "../utils/content"

const DATA_TYPE = "world-activity"
const PAGE_NAME = "world-activity"

const SCREEN_MOBILE_WIDTH = 639

const INITIAL_VIEW_STATE = {
  zoom: 0,
  longitude: -69,
}
const INITIAL_VIEW_STATE_FINAL = {
  latitude: 40.418889,
  longitude: -70,
  zoom: 0,
  pitch: 0,
  bearing: 0,
  transitionDuration: 500,
  // transitionInterpolator: new FlyToInterpolator(),
}

const Activity = () => {
  const size = useWindowSize()
  const prevSize = usePrevious(size.width)
  const [slot, setSlot] = useState(1)
  const [isMobile, setIsMobile] = useState(false)

  const NUM_COLUMNS_WEIGHT = 5
  const data = useStaticQuery(graphql`
    {
      allWorldActivityJson {
        edges {
          node {
            position
            t1
            t2
            t3
            t4
            t5
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (size.width <= SCREEN_MOBILE_WIDTH) {
      setupMobile()
    }
  }, [])

  useEffect(() => {
    // checks
    const screenToMobile =
      prevSize > SCREEN_MOBILE_WIDTH && size.width <= SCREEN_MOBILE_WIDTH
    const screenFromMobile =
      prevSize <= SCREEN_MOBILE_WIDTH && size.width > SCREEN_MOBILE_WIDTH

    if (screenToMobile) {
      setupMobile()
    } else if (screenFromMobile) {
      setupDesktop()
    }
  }, [size])

  const setupMobile = () => {
    setIsMobile(true)
  }

  const setupDesktop = () => {
    setIsMobile(false)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSlot(slot => {
        if (slot === NUM_COLUMNS_WEIGHT) {
          return 1
        }
        return slot + 1
      })
    }, 1500)
    return () => clearInterval(interval)
  }, [])

  const getPosition = d => d.node.position

  const intensity = 1.1
  const colorRange = [
    [60, 220, 255, 0.1],
    [60, 220, 255],
    [93, 178, 222],
    [127, 136, 190],
    [160, 95, 157],
    [194, 53, 125],
    [227, 11, 92],
  ]

  return (
    <Layout view={"map"}>
      <SEO title="Activity" />
      <HeatmapActivity
        data={data.allWorldActivityJson.edges}
        mapType={DATA_TYPE}
        initialViewState={INITIAL_VIEW_STATE}
        initialViewStateFinal={
          isMobile
            ? {
                ...INITIAL_VIEW_STATE_FINAL,
                latitude: 10,
                longitude: 0,
              }
            : INITIAL_VIEW_STATE_FINAL
        }
        getPosition={getPosition}
        colorRange={colorRange}
        intensity={intensity}
        numColumnsWeight={NUM_COLUMNS_WEIGHT}
        slot={slot}
        // links={content
        //   .filter(page => page.id.includes("activity"))
        //   .map(page => ({
        //     id: page.id,
        //     title: page.title,
        //     url: page.url,
        //   }))}
        textContent={content.filter(page => page.id === PAGE_NAME)[0]}
      />
    </Layout>
  )
}

export default Activity
