/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import { Progress } from "theme-ui"
import { FlyToInterpolator } from "deck.gl"

import SideboxMapView from "../views/sidebox-map-view"
import DeckGLMap from "../maps/deckgl/deckgl-map"
import heatmapLayer from "../maps/deckgl/heatmap-layer"

import { LIGHT_MINIMALIST, DARK_MINIMALIST } from "../../utils/constants"

const HeatmapActivity = ({
  data,
  mapType,
  initialViewState,
  initialViewStateFinal,
  getPosition,
  numColumnsWeight,
  slot,
  colorRange,
  intensity,
  textContent,
}) => {
  const [loadedMap, setLoadedMap] = useState(false)
  const [layers, setLayers] = useState([])
  const [viewState, setViewState] = useState(null)

  // Initial data preprocessing
  useEffect(() => {
    setLayers(renderLayers(slot))
  }, [])

  useEffect(() => {
    setLayers(renderLayers(slot))
  }, [data])

  useEffect(() => {
    setLayers(renderLayers(slot))
  }, [slot])

  // Helper to request the layer from the corresponding renderer
  const renderLayers = slot => {
    return [
      heatmapLayer({
        data,
        layerName: `${mapType}${slot}`,
        getPosition,
        getWeight: d => d.node[`t${slot}`],
        colorRange,
        intensity,
      }),
    ]
  }

  // const handleSelectChange = e => {
  //   navigate(e.target.value)
  // }

  const onLoadMap = () => {
    setTimeout(() => {
      setLoadedMap(true)
    }, 3000)

    if (initialViewStateFinal) {
      setViewState({
        ...initialViewStateFinal,
        transitionInterpolator: new FlyToInterpolator(),
      })
    }
  }

  const onViewStateChange = state => {
    setViewState(state.viewState)
  }

  return (
    layers.length > 0 && (
      <SideboxMapView
        viewType={"activity"}
        loadedMap={loadedMap}
        map={
          <>
            <DeckGLMap
              mapType={mapType}
              layers={renderLayers(slot)}
              onLoadMap={onLoadMap}
              extendedInitialView={initialViewState}
              viewState={viewState}
              onViewStateChange={onViewStateChange}
              darkBase={DARK_MINIMALIST}
              lightBase={LIGHT_MINIMALIST}
            />
            <Progress
              color={"secondary"}
              max={numColumnsWeight - 1}
              value={slot - 1}
              sx={{ position: "absolute", zIndex: 1, bottom: 0 }}
            />
          </>
        }
        text={
          <>
            <br />
            <div sx={{ variant: "styles.pageText", fontWeight: "heading" }}>
              Last 24 hours activity
            </div>
            {textContent.description.map((item, index) => (
              <p
                key={`interval-story-${index}`}
                sx={{ variant: "styles.pageTextSmall" }}
              >
                {item}
              </p>
            ))}
          </>
        }
      />
    )
  )
}

export default HeatmapActivity
