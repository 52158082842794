import React from "react"
import { HeatmapLayer } from "@deck.gl/aggregation-layers"

export default function heatmapLayer({
  data,
  layerName,
  getPosition,
  getWeight,
  colorRange,
  intensity,
}) {
  return new HeatmapLayer({
    id: layerName ? `heatmap-${layerName}` : "heatmap",
    data,
    getPosition: getPosition || (d => d.position),
    getWeight: getWeight || 5,
    colorRange: colorRange || [
      // [60, 220, 255, 0.4],
      // [60, 220, 255, 0.7],
      // [60, 220, 255, 0.1],
      [60, 220, 255],
      [93, 178, 222],
      [127, 136, 190],
      [160, 95, 157],
      [194, 53, 125],
      [227, 11, 92],
    ],
    intensity: intensity || 1.5,
  })
}
